
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import BackTop from '@/components/backtop/Index.vue';

export default defineComponent({
  components: {
    BackTop
  },
  setup() {
    const { t } = useI18n();
    const myBackToTopStyle = {
      right: '50px',
      bottom: '50px',
      width: '40px',
      height: '40px',
      'border-radius': '4px',
      'line-height': '45px', // Please keep consistent with height to make it center vertically
      background: '#e7eaf1'
    };
    return {
      t,
      myBackToTopStyle
    };
  }
});
